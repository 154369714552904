import { Outlet } from '@remix-run/react'
import { AppLayout } from '~/components/ui/custom_ui/appLayout'

export default function Publiclayout() {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  )
}
